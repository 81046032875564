import ApiService from "@/common/api.service";

export default {
  fetchTasks() {
    return ApiService.get("task/tasks");
  },
  fetchSubtasks(userId) {
    return ApiService.get(`task/${userId}/sub-tasks`);
  },
  fetchSubtask(subtaskId) {
    return ApiService.get(`task/sub-task/${subtaskId}`);
  },
  updateSubtaskProgress(subtaskId, progress) {
    return ApiService.patch(`task/sub-task/${subtaskId}/progress`, {
      progress
    });
  },
  createSubtask(subtask) {
    return ApiService.post("task/sub-task", subtask);
  },
  updateSubtask(subtaskId, subtask) {
    return ApiService.patch(`task/sub-task/${subtaskId}`, subtask);
  },
  deleteSubtask(subtaskId) {
    return ApiService.delete(`task/sub-task/${subtaskId}`);
  }
};
