<template>
  <el-form v-if="newSubtask" ref="form" :model="newSubtask" label-width="100px">
    <el-form-item label="Status" prop="status">
      <el-select v-model="newSubtask.progress">
        <el-option
          :key="status"
          v-for="status in statuses"
          :value="status"
          :label="$t(`tasks.${status}`)"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      required
      label="Task"
      prop="task_id"
      :rules="[commonRules.input]"
    >
      <el-select class="w-100" v-model="newSubtask.task_id">
        <el-option
          :key="task.id"
          v-for="task in tasks"
          :value="task.id"
          :label="task.title[$i18n.locale]"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      required
      label="Title"
      prop="title"
      :rules="[commonRules.input]"
    >
      <el-input v-model="newSubtask.title" />
    </el-form-item>
    <el-form-item
      required
      label="Description"
      prop="description"
      :rules="[commonRules.input]"
    >
      <el-input type="textarea" v-model="newSubtask.description" />
    </el-form-item>
    <el-form-item
      required
      label="Start Date"
      prop="start_date"
      :rules="[commonRules.input]"
    >
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="newSubtask.start_date"
      />
    </el-form-item>
    <el-form-item
      required
      label="Due Date"
      prop="due_date"
      :rules="[commonRules.input]"
    >
      <el-date-picker value-format="yyyy-MM-dd" v-model="newSubtask.due_date" />
    </el-form-item>
    <el-row type="flex" justify="center">
      <el-button @click="$emit('onGoToTasksPage')">
        {{ $t("button.cancel") }}
      </el-button>
      <el-button type="primary" @click="onSubmit">
        {{ $t("button.save") }}
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
import tasksApi from "@/apis/tasks";
import formMixin from "@/mixins/form";
import { EnumTaskStatuses } from "@/enums";

export default {
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  mixins: [formMixin],
  computed: {
    statuses() {
      return [
        EnumTaskStatuses.UNFINISHED,
        EnumTaskStatuses.PROCESSING,
        EnumTaskStatuses.FINISHED
      ];
    }
  },
  data() {
    return {
      tasks: [],
      newSubtask: null
    };
  },
  async created() {
    await this.setTasks();
    this.newSubtask = {
      title: "",
      task_id: null,
      description: "",
      start_date: null,
      due_date: null,
      progress: EnumTaskStatuses.UNFINISHED,
      ...this.subtask
    };
  },
  methods: {
    async setTasks() {
      const { tasks } = await tasksApi.fetchTasks();
      this.tasks = tasks;
    },
    async onSubmit() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (valid) {
        this.$emit("onSubmit", this.newSubtask);
      }
    }
  }
};
</script>
